<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page2">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_shadow m_8">
                  <div class="box box_one">
                    <div class="box_list_wrap">
                      <div class="box_list">
                        <div class="list_ttl">
                          <div class="left">
                            <div class="m_4" v-show="item.type === 1">
                              <span class="build_name">{{
                                item.buildingName
                              }}</span>
                            </div>
                            <p class="white_wrap ft_m_16">
                              {{ item.title }}
                            </p>
                            <div class="price_box" v-show="item.type === 1">
                              <span class="price_txt">{{
                                $dayjs(item.regDate).format('YYYY.MM.DD')
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="line_grey none"></div>
                    </div>
                    <div class="text_box editor">
                      <span v-html="item.contents"></span>
                      <!--                      <div class="img_box_full">-->
                      <!--                        <img-->
                      <!--                          src="@/assets/static/images/ex_img7.png"-->
                      <!--                          alt="img"-->
                      <!--                        />-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const layoutState = () => {
  const { getters, dispatch } = useStore()
  const routeLayout = computed(() => {
    return getters['layout/getRouteLayout']
  })
  const fnUpdateRouteLayout = ({ titleName = '' }) => {
    dispatch('layout/updateRouteLayout', {
      titleName: titleName
    })
  }
  return { routeLayout, fnUpdateRouteLayout }
}

export default {
  name: 'menu-board-view',
  description: '공지사항/자주묻는질문 상세 페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const query = route.query

    const state = reactive({
      item: {}
    })

    const fnGetBoard = async () => {
      try {
        const res = await proxy.$BoardSvc.fetchBoard(query)
        if (res.code === 1) {
          state.item = res.entity
        }
      } catch (e) {
        console.log(e)
      }
    }

    const { fnUpdateRouteLayout } = layoutState()
    let titleName = ''
    if (Number(query.type) === proxy.$ConstCode.BOARD_TYPE.NOTICE.value) {
      titleName = proxy.$ConstCode.BOARD_TYPE.NOTICE.name
    } else if (Number(query.type) === proxy.$ConstCode.BOARD_TYPE.FAQ.value) {
      titleName = proxy.$ConstCode.BOARD_TYPE.FAQ.name
    }

    fnUpdateRouteLayout({ titleName })
    fnGetBoard()
    return {
      ...layoutState(),
      ...toRefs(state),
      fnGetBoard
    }
  }
}
</script>

<style scoped></style>
